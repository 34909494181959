<template>
  <el-dialog center
    width="550px"
    :visible="visible"
    @close="$emit('close', $event)">
    <template #title>
      <img v-if="showMegaportLogo"
        src="@/assets/images/megaport-logos/Megaport-Logo-Web-72dpi-RGB.png"
        :alt="$t('images.megaport-logo')"
        width="240"
        height="69">
      <h2 v-if="title">
        {{ title }}
      </h2>
    </template>

    <slot />

    <template #footer>
      <el-button v-if="buttonText"
        type="primary"
        @click="$emit('button-click', $event)">
        {{ buttonText }}
      </el-button>
    </template>
  </el-dialog>
</template>

<script>
export default {
  name: 'ResetPasswordErrorDialog',

  props: {
    visible: {
      type: Boolean,
      required: true,
    },

    title: {
      type: String,
      required: false,
      default: '',
    },

    buttonText: {
      type: String,
      required: false,
      default: '',
    },

    showMegaportLogo: {
      type: Boolean,
      required: false,
      default: true,
    },
  },

  emits: ['button-click', 'close'],

}
</script>

<style scoped>
::v-deep .el-dialog__body {
  text-align: center !important;
}
</style>
