<template>
  <auth-card v-loading="resetFormProcessing"
    class="reset-card"
    shadow="always"
    show-close-btn
    :element-loading-text="$t('authentication.resetting')">
    <template #description>
      <el-form ref="resetForm"
        :model="resetForm"
        :rules="resetRules">
        <fieldset class="auth-fieldset">
          <legend>{{ $t('authentication.reset') }}</legend>
          <p v-if="showStaticEmail"
            class="reset-email my-1">
            {{ resetEmail }}
          </p>

          <el-form-item v-if="!showStaticEmail"
            :label="$t('general.email')"
            prop="email">
            <el-input v-model="resetForm.email"
              name="email"
              autocomplete="email"
              :placeholder="$t('general.email')" />
          </el-form-item>

          <el-form-item :label="$t('authentication.new-password-no-your')"
            prop="password">
            <el-input v-model="resetForm.password"
              :placeholder="$t('general.password')"
              name="password"
              type="password"
              autocomplete="off">
              <template #append>
                <el-popover placement="top"
                  :title="$t('authentication.password-must-include')"
                  width="300"
                  trigger="hover">
                  <div class="text-align-left">
                    <ul class="pl-2">
                      <li>{{ $t('authentication.at-least-8-char') }}</li>
                      <li>{{ $t('authentication.at-least-one-number') }}</li>
                      <li>{{ $t('authentication.at-least-one-symbol') }}</li>
                      <li>{{ $t('authentication.at-least-one-lower-case-letter') }}</li>
                      <li>{{ $t('authentication.at-least-one-upper-case-letter') }}</li>
                    </ul>
                  </div>
                  <span slot="reference">
                    <i class="el-icon-info color-info"
                      aria-hidden="true" />
                  </span>
                </el-popover>
              </template>
            </el-input>
          </el-form-item>

          <el-form-item :label="$t('authentication.confirm-new-password')"
            prop="confirmPassword">
            <el-input v-model="resetForm.confirmPassword"
              :placeholder="$t('authentication.confirm-password')"
              name="confirmPassword"
              autocomplete="password"
              type="password" />
          </el-form-item>

          <div class="text-align-center mt-4">
            <el-button type="primary"
              class="full-width"
              @click="resetPassword">
              {{ $t('authentication.reset') }}
            </el-button>

            <h5 class="or">
              <span>{{ $t('authentication.or') }}</span>
            </h5>

            <el-button class="full-width"
              @click="$router.push('/login', () => {})">
              {{ $t('authentication.log-in') }}
            </el-button>
          </div>
        </fieldset>
      </el-form>

      <reset-password-error-dialog :visible="invalidLinkDialogVisible"
        :title="$t('authentication.reset-expired-dialog-title')"
        :button-text="$t('authentication.resend-link')"
        @close="invalidLinkClose()"
        @button-click="resendLink()">
        {{ $t('authentication.reset-expired-dialog-desc') }}
      </reset-password-error-dialog>

      <reset-password-error-dialog :visible="unexpectedErrorDialogVisible"
        :title="$t('authentication.unexpected-error-dialog-title')"
        @close="unexpectedErrorDialogVisible = false">
        {{ $t('authentication.unexpected-error-dialog-desc') }}
      </reset-password-error-dialog>
    </template>
  </auth-card>
</template>

<script>
import { passStrength } from '@/utils/passwordStrength.js'
import { validatePassword } from '@/validators.js'
import sdk from '@megaport/api-sdk'

import { showModalAlert } from '@/components/authentication/AuthHelpers.js'
import ResetPasswordErrorDialog from '@/components/authentication/reset-password/ResetPasswordErrorDialog.vue'
import AuthCard from '@/components/ui-components/AuthCard.vue'

export default {
  name: 'ResetPassword',

  components: {
    'reset-password-error-dialog': ResetPasswordErrorDialog,
    'auth-card': AuthCard,
  },

  data() {
    return {
      invalidLinkDialogVisible: false,
      unexpectedErrorDialogVisible: false,

      resetForm: {
        email: '',
        password: '',
        confirmPassword: '',
      },
      resetRules: {
        email: [
          { required: true, message: this.$t('validations.required', { thing: 'Email' }), trigger: 'blur' },
          { type: 'email', message: this.$t('validations.email-invalid'), trigger: 'blur' },
        ],
        password: { required: true, validator: this.validateResetPassword, trigger: 'blur' },
        confirmPassword: { required: true, validator: this.validateConfirmPassword, trigger: 'blur' },
      },
      resetFormProcessing: false,
    }
  },

  computed: {
    resetPassStrength() {
      return passStrength(this.resetForm.password)
    },

    resetCode() {
      return this.$route.query.code ? this.$route.query.code : this.$route.hash?.replace(/#/g, '')
    },

    resetEmail() {
      return this.$route.query.email ? this.$route.query.email : this.resetForm.email
    },

    /**
     * There will temporarily be two formats for the reset url.
     * If the email is in a query param, make sure we hide the input element and display it as static text instead.
     * If no email is in the url, we will display the form input for now.
     */
    showStaticEmail() {
      return (this.$route.query.email)
    },
  },

  created() {
    if (!this.resetCode) {
      this.invalidLinkDialogVisible = true
    }
  },

  methods: {
    handleRequestError(e) {
      this.resetFormProcessing = false

      if (!e.handled) {
        // 406 = reset link is expired or invalid
        if (e.status === 406) {
          this.invalidLinkDialogVisible = true
        } else if (e.status === 400 && e.data?.message) {
          // display 400 errors from BE to user, as will be further feedback on password issues
          showModalAlert(e.data.message, this.$t('general.error-updating', { thing: 'password' }), 'error')
        } else {
          this.unexpectedErrorDialogVisible = true
        }
      }
    },
    invalidLinkClose() {
      this.invalidLinkDialogVisible = false
      this.$router.push('/login', () => { /* empty function is intentional */ })
    },
    /* Resend the password reset link, used in expired link modal */
    resendLink() {
      sdk.instance
        .passwordRequest(this.resetEmail)
        .then(() => {
          showModalAlert(this.$t('authentication.reset-message'), this.$t('authentication.password-reset-title'))
          this.$router.push('/login', () => { /* empty function is intentional */ })
        })
        .catch(this.handleRequestError)
    },
    resetPassword() {
      this.$refs['resetForm'].validate(valid => {
        if (!valid) return

        this.resetFormProcessing = true

        sdk.instance
          .passwordReset(this.resetEmail, this.resetCode, this.resetForm.password)
          .then(() => {
            this.resetFormProcessing = false
            showModalAlert(this.$t('authentication.password-change-successful'), this.$t('authentication.password-reset-title'))
            this.$router.push('/login', () => { /* empty function is intentional */ })
          })
          .catch(this.handleRequestError)
      })
    },
    /**
     * Validate password strength
     * @param {Object} rule
     * @param {string} value
     * @param {Function} callback
     */
    validateResetPassword (rule, value, callback) {
      if (value.length === 0) {
        callback(this.$t('validations.new-password'))
      } else {
        const passwordAnalysis = this.resetPassStrength
        validatePassword(rule, value, callback, passwordAnalysis)
      }
    },
    /**
     * Validate password and confirm password fields match
     * @param {Object} _rule
     * @param {string} value
     * @param {Function} callback
     */
    validateConfirmPassword(_rule, value, callback) {
      if (value !== this.resetForm.password) {
        callback(new Error(this.$t('validations.confirm-password-mismatch')))
      } else {
        callback()
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.reset-card {
  h3 {
    font-size: 14px;
  }

  p.reset-email {
    font-size: 16px;
    color: #808080;
  }

  p.text-divider {
    display: flex;
    text-transform: uppercase;
    flex-direction: row;
    color: #414141;
    margin: 2rem auto;
    &:before,
    &:after {
      content: "";
      flex: 1 1;
      border-bottom: 1px solid #ebeef5;
      margin: auto;
    }

    &:before {
      margin-right: 1rem;
    }

    &:after {
      margin-left: 1rem;
    }
  }
}
</style>
